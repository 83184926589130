<template>
  <div>
    <h1><icon class="mr-1" type="cog" size="0.9" /> {{ name }}</h1>

    <label-with-edit-icon-and-content
        label="displayName"
        :value="displayName"
        v-if="!isEditing('displayName')"
        @edit="setEditing('displayName')"
        :make-placeholder-space="false"
    />
    <div v-else>
      <input
          type="text"
          :class="['form-text mt-4', $v.displayName.$error ? 'form-group--error' : '']"
          :id="'displayName'"
          v-model="displayName"
          :placeholder="$t('displayName')"

      >
      <div class="edit-trigger mr-2" @click="cancelEditing()">
        <icon :type="'times'"/>
      </div>
      <div class="edit-trigger" @click="update('displayName')">
        <icon :type="'save'"/>
      </div>
      <div class="form-error" v-if="!$v.displayName.required && $v.displayName.$error">{{ $t('errors.required') }}</div>
      <div class="form-error" v-if="!$v.displayName.maxLength">{{ $t('errors.atMostCharacters', {num: 128}) }}</div>
      <div class="form-error" v-if="!$v.displayName.minLength">{{ $t('errors.atLeastCharacters', {num: 3}) }}</div>
    </div>

    <label-with-edit-icon-and-content
        label="description"
        :value="description"
        v-if="!isEditing('description')"
        @edit="setEditing('description')"
        :make-placeholder-space="false"
    />
    <div v-else>
     <textarea
         :class="['form-text', $v.description.$error ? 'form-group--error' : '']"
         :id="'description'"
         v-model="description"
         :placeholder="$t('enterdeschere')"
     />
      <div class="edit-trigger mr-2" @click="cancelEditing()">
        <icon :type="'times'"/>
      </div>
      <div class="edit-trigger" @click="update('description')">
        <icon :type="'save'"/>
      </div>
      <div class="form-error" v-if="!$v.description.maxLength">{{ $t('errors.atMostCharacters', {num: 128}) }}</div>
    </div>
  </div>
</template>

<script>
import { required, minLength, maxLength } from 'vuelidate/lib/validators';
import {alphaNumSpace} from '@/customValidators';
import Icon from "@/components/Icon";
import { mapState } from 'vuex';
import DateMixin from "@/components/mixins/DateMixin.js.vue";
import LabelWithEditIconAndContent from "@/components/LabelWithEditIconAndContent";

export default {
  name: "ServiceAddForm",
  components: {
    Icon,
    LabelWithEditIconAndContent,
  },
  mixins: [DateMixin],
  props: {
    organizationId: {type: String, default: ''},
    /**
     * @param serviceId the uuid of the service
     * */
    serviceId: {type: String, default: ''}
  },
  data() {
    return {
      description: '',
      name: '',
      displayName: '',
      enableSaving: false,
      error: '',
      fieldEditing: ''
    };
  },
  computed: mapState({currentUserOrg: state => state.users.currentUserOrg,}),
  watch: {
    name() {
      this.error = null;
    },
    '$v.$invalid': function() {
      this.checkSaving();
    },
    serviceId(val) {
      if(val) {
        this.loadServiceData();
      }
    },
  },
  validations: {
    name: {
      required,
      minLength: minLength(3),
      maxLength: maxLength(127),
      alphaNumSpace,
      async isUnique(value) {
        // standalone validator ideally should not assume a field is required
        if (value === '') {
          return true;
        }
        let bool = true;
        await this.$store.dispatch('checkIfServiceNameExists', {type: 'Service', name: value}).then(data => {
          bool = (data.length === 0 || (data[0].id === this.serviceId));
        });
        return bool;
      },
    },
    displayName: {
      required,
      minLength: minLength(3),
      maxLength: maxLength(127),
    },
    description: {
      maxLength: maxLength(1024),
    },
  },
  beforeMount() {
    if(this.serviceId) {
      this.loadServiceData();
    }
  },
  methods: {
    loadServiceData() {
      this.$store.dispatch('loadService', {
        id: this.serviceId,
      }).then(data => {
        if(data) {
          this.name = data.name;
          this.displayName = data.displayName;
          this.description = data.description;
        }
      });
    },
    checkSaving() {
      this.enableSaving = this.name && !this.$v.$invalid;
    },
    update: async function(fieldname) {
      if (!this.$v.$anyError) {
        let args = {[fieldname]: this[fieldname]}
        args.id = this.serviceId;
        await this.$store.dispatch('updateService', args).then(() => {
          this.$emit('finished');
          this.fieldEditing = '';
          this.loadServiceData();
        }).catch(e => {
          console.log(e);
          this.error = e;
        });
      }
    },
    setEditing(fieldName) {
      this.fieldEditing = fieldName;
    },
    isEditing(fieldName) {
      return this.fieldEditing === fieldName;
    },
    cancelEditing: function () {
      this.fieldEditing = '';
      this.loadOrganization();
    },
  }
}
</script>